<template>
  <div class="ddj-clickCount">
    <div class="main-Title bgff"><h2>Device Overlap Rate</h2></div>
    <div class="mainBox">
      <el-card shadow="hover">
        <el-form :model="filter" ref="filter" label-width="80px" size="mini" :rules="rules">
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="Country:" class="mb0" prop="countryCode">
                <el-select
                  v-model="filter.countryCode"
                  placeholder="Please select"
                  class="w100"
                  filterable
                >
                  <el-option label="全部" value="" />
                  <el-option
                    v-for="item in options.country"
                    :key="item.id"
                    :value="item.countryCode"
                    :label="item.countryCode"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="platform:" label-width="80px" class="mb0" prop="platform">
                <el-select
                  v-model="filter.platform"
                  placeholder="Please select platform"
                  class="w100"
                >
                  <el-option
                    v-for="item in options.os"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="appId:" class="mb0" prop="appId">
                <el-input
                  v-model="filter.appId"
                  placeholder="Please enter APP ID"
                  class="mr10"
                ></el-input
              ></el-form-item>
            </el-col>
            <el-col :span="2"
              ><el-button
                type="primary"
                :loading="loading.filter"
                size="mini"
                @click="getList('filter')"
                >Search</el-button
              ></el-col
            >
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-row class="pt10 pb10 mb10 font-18">
          <el-col :span="8">
            <strong class="pr10">{{ filter.countryCode }} 近7天设备数: {{ totalCount }}</strong>
            <el-tooltip
              class="item"
              effect="dark"
              content="按照国家和platform过滤的最近7天总设备数量"
              placement="right"
            >
              <i class="el-icon-question font-14 cor999"></i>
            </el-tooltip>
          </el-col>
          <el-col :span="8">
            <strong class="pr10">{{ filter.appId }} 安装数: {{ targetCount }}</strong>
            <el-tooltip
              class="item"
              effect="dark"
              content="目标app最近7天的设备数量"
              placement="right"
            >
              <i class="el-icon-question font-14 cor999"></i>
            </el-tooltip>
          </el-col>
          <el-col :span="8">
            <strong class="pr10">安装比例: {{ installRate }}%</strong>
            <el-tooltip
              class="item"
              effect="dark"
              content="目标app占7天总设备数的比例"
              placement="right"
            >
              <i class="el-icon-question font-14 cor999"></i>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          style="width:100%;"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column label="Country" prop="countryCode"></el-table-column>
          <el-table-column label="appid" prop="appId"></el-table-column>
          <el-table-column label="device_count" prop="deviceCount">
            <template slot="header">
              device_count
              <el-tooltip effect="dark" content="表示某个app最近七天的设备总数" placement="right">
                <i class="el-icon-question font-14"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="安装比例" prop="installRate">
            <template slot="header">
              安装比例
              <el-tooltip
                effect="dark"
                content="表示某个app设备数量占七天总设备数的比例"
                placement="right"
              >
                <i class="el-icon-question font-14"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope"> {{ scope.row.installRate }}%</template>
          </el-table-column>
          <el-table-column label="app_重合度" prop="overlapRate">
            <template slot="header">
              app_重合度
              <el-tooltip
                effect="dark"
                content="表示同时安装目标app和该app的设备数占该app设备数的比例"
                placement="right"
              >
                <i class="el-icon-question font-14"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope"> {{ scope.row.overlapRate }}%</template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <!-- <div v-if="isShowPage" class="pagination-container pt-10" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div> -->
      </el-card>
    </div>
  </div>
</template>

<script>
  import deviceOverlapCon from '../../controllers/DDJ/v3/deviceOverlap';
  export default {
    name: 'DeviceOverlap',
    ...deviceOverlapCon,
  };
</script>

<style scoped></style>
