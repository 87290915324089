import request from 'utils/request';
const api = {
  DEVICE_OVERLAP_PAGE: '/api/admin/mc/report/device/overlap/query',
};

// 分页
export function fetchDeviceOverlapPage(query) {
  return request({
    url: api.DEVICE_OVERLAP_PAGE,
    method: 'get',
    params: query,
  });
}
