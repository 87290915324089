import optionData from '@/assets/js/optionData';
import { fetchDeviceOverlapPage } from '@/api/DDJ/v3/deviceOverlap';
import { fetchCountryList } from '@/api/DDJ/v3/country';

export default {
  components: {},
  data() {
    return {
      filter: {
        platform: 'IOS',
      },
      options: {
        country: [],
        os: optionData.osOption,
        advertiser: [],
      },
      list: [],
      installRate: 0,
      targetCount: 0,
      totalCount: 0,
      loading: {
        list: false,
        filter: false,
      },
      pages: {
        pageNum: 1,
        pageSize: 20,
      },
      pagesTotal: 0,
      isShowPage: false,
      rules: {
        countryCode: [
          {
            required: true,
            message: 'Please enter the country',
            trigger: 'blur',
          },
        ],
        appId: [
          {
            required: true,
            message: 'Please enter APP ID',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  mounted() {
    this.getCountry();
  },
  computed: {},
  methods: {
    // 获取国家
    getCountry() {
      fetchCountryList().then((res) => {
        this.options.country = res.result;
      });
    },
    // 列表
    getList(types) {
      this.$refs[types].validate((valid) => {
        if (!valid) return;
        if (types == 'filter') {
          this.pages.pageNum = 1;
          this.isShowPage = false;
        }
        let query = Object.assign({}, this.filter);
        query.platform = this.filter.platform.toLowerCase();
        this.loading.filter = true;
        this.loading.list = true;
        fetchDeviceOverlapPage(query)
          .then((res) => {
            let data = res.result;
            this.list = [];
            this.list = data.appOverLapRates;
            this.installRate = data.installRate;
            this.targetCount = data.targetCount;
            this.totalCount = data.totalCount;
            // this.pagesTotal = data.total;
            this.loading.filter = false;
            this.loading.list = false;
            this.isShowPage = true;
          })
          .catch((e) => {
            this.loading.filter = false;
            this.loading.list = false;
            this.$message.error(e.message);
          });
      });
    },
    // 分页监听
    handleCurrentChange(val) {
      this.pages.pageNum = val;
      this.getList();
    },
  },
};
